<template>
  <div
    class="mobile-keyboard-button">
    <div
      class="mobile-keyboard-button__placeholder"
      @mousedown="handleMousedown">
      <el-button
        :type="placeholderType"
        :plain="placeholderPlain"
        @click.stop="toggleButtonPopover">
        {{text || '\u200b'}}
      </el-button>
    </div>
    <!--<transition name="fade">
      <div class="keyboard-button-background" v-if="type === 'rich' && editorJsPopupActive"/>
    </transition>-->
    <transition name="fade-200">
      <div
        class="mobile-keyboard-button__wrapper-wrapper"
        @click="closeButtonPopover"
        v-if="buttonPopoverActive">
        <div
          class="mobile-keyboard-button__wrapper">
          <!--<div class="keyboard-button-arrow">
          <div class="arrow" :style="{left: left+'px'}"/>
        </div>-->
          <div
            @click.stop
            class="mobile-keyboard-button__content">
            <div class="mobile-keyboard-button__controls-row">
              <el-dropdown
                trigger="click"
                @command="handleTypeSelect">
                <span class="button button_size_sm">
                  {{buttonTypeLabel}}
                  <el-icon name="arrow-down"/>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="type in buttonTypes"
                    :key="type"
                    :command="type">
                    {{getButtonTypeLabel(type)}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <div class="spacer"/>

              <transition name="fade-200">
                <keyboard-button-sign
                  :is-error="isError"
                  class="mobile-keyboard-button__sign"/>
              </transition>

              <button
                class="button button_theme_danger button_size_sm"
                :title="$t('keyboard.deleteButton')"
                @click="deleteButton">
                <el-icon name="delete"/>
              </button>
              <button
                class="button button_theme_success button_size_sm"
                :title="$t('keyboard.closeButtonPopup')"
                @click="closeButtonPopover">
                <el-icon name="check"/>
              </button>
            </div>

            <el-autocomplete
              class="mobile-keyboard-button__content-item"
              :fetch-suggestions="searchTextSuggestions"
              :placeholder="$t('keyboard.placeholders.text')"
              :value="text"
              @input="updateText($event)"/>

            <div
              class="keyboard-button__article-row mobile-keyboard-button__content-item"
              v-if="type === 'article'">
              <button
                class="button keyboard-button__article-button"
                @click.stop="toggleEditorJsPopover">
                {{$t('keyboard.openEditor')}}
              </button>
              <label class="switch ml-1 keyboard-button__article-button">
                <input
                  type="checkbox"
                  class="switch__input"
                  :checked="isIndexed"
                  @input="$emit('update:is-indexed', $event.target.checked)"/>
                <span class="switch__core">
                  <div class="switch__icon"/>
                  {{$t('keyboard.addToStats')}}
                </span>
              </label>
            </div>

            <transition name="fade">
              <editor-js-popup
                v-if="editorJsPopupActive"
                :title="text"
                @update:title="$emit('update:text', $event)"
                :content="content"
                @update:content="$emit('update:content', $event)"
                @close="handleDone"
                @delete="deleteButton"/>
            </transition>

            <div class="answer-input mobile-keyboard-button__content-item" v-if="type === 'answer'">
              <el-input
                type="textarea"
                autosize
                :placeholder="$t('keyboard.placeholders.answer')"
                :value="answer"
                @input="$emit('update:answer', $event)"/>

              <el-tooltip
                effect="dark"
                :content="charCounterTooltip"
                placement="top"
                v-if="charLimitExceeded">
                <span class="answer-input__char-counter" :class="{'answer-input__char-counter_danger': charLimitExceeded}">
                  {{answer.length}}/{{answerCharLimit}}
                </span>
              </el-tooltip>
              <span
                class="answer-input__char-counter"
                :class="{'answer-input__char-counter_danger': charLimitExceeded}"
                v-else>
                {{answer.length}}/{{answerCharLimit}}
              </span>
            </div>

            <el-input
              v-if="type === 'link'"
              autosize
              class="mobile-keyboard-button__content-item"
              :placeholder="$t('keyboard.placeholders.link')"
              :maxlength="2048"
              :value="link"
              @input="$emit('update:link', $event)"/>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
  import KeyboardButtonSign from '@/components/Keyboard/KeyboardButtonSign.vue'

  const EditorJsPopup = () => import('@/components/Keyboard/EditorJsPopup.vue')

  import KeyboardButtonMixin from '@/components/Keyboard/KeyboardButton/KeyboardButtonMixin'

  export default {
    name: 'MobileKeyboardButton',
    components: {
      KeyboardButtonSign,

      EditorJsPopup,
    },
    mixins: [
      KeyboardButtonMixin,
    ],
    data: () => ({
      buttonPopoverActive: false,
    }),
    methods: {
      handleMousedown(e) {
        if (this.buttonPopoverActive) {
          return e.stopPropagation()
        }
      },

      toggleButtonPopover() {
        this.buttonPopoverActive = !this.buttonPopoverActive
      },
      closeButtonPopover() {
        this.buttonPopoverActive = false
      }
    },
    computed: {
      placeholderType() {
        if (this.isError) {
          return 'danger'
        }
      },
      placeholderPlain() {
        if (this.isError) {
          return true
        }
      }
    },
    mounted() {
      // document.addEventListener('mousedown', this.handleDone)
    },
    beforeDestroy() {
      // document.removeEventListener('mousedown', this.handleDone)
    },
  }
</script>

<style scoped lang="stylus">
  @import '~@/components/Keyboard/KeyboardButton/KeyboardButton.styl'

  .mobile-keyboard-button__wrapper-wrapper
    @apply fixed inset-0
    z-index 102
    @apply flex flex-col items-stretch justify-center flex-grow overflow-auto
    background-color rgba(0, 0, 0, .5)

  .mobile-keyboard-button__wrapper
    transition 200ms
    @apply px-2 py-12
    max-height 100%
    overflow auto

  .mobile-keyboard-button__content
    @apply p-2 bg-white
    @apply border border-solid border-gray-300 rounded
    @apply flex flex-col
    box-shadow 0 2px 12px 0 rgba(0, 0, 0, .1)
    width 100%
    max-width 660px

  .mobile-keyboard-button__controls-row
    @apply flex flex-row items-center

  .mobile-keyboard-button__sign
    @apply mr-2

  .mobile-keyboard-button__editor-js
    min-height 50vh

  .mobile-keyboard-button__content-item:not(:first-child)
    @apply mt-1
</style>

<style lang="stylus">
  .url-input.error input
    border-color #f56c6c !important

  .answer-input
    .el-textarea__inner
      padding 8.5px 15px

  .mobile-keyboard-button__content .emoji
    margin: 0 1px;
    --emoji-size 18px
</style>

<style lang="stylus">
  .button-transition-enter-active, .button-transition-leave-active
    transition 5s

  .button-transition-enter, .button-transition-leave-to
    height 0 !important
</style>